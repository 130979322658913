<template>
  <sign-page
    title-text="维修申请记录"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :title-menus="[]"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="维修申请详细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.id"></detail>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  assetsRepairApplyRequest as request,
  statusManageRequest
} from '../../api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'

export default {
  components: {
    detail
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'back',
        label: '撤销',
        show (data) {
          return data.actions.find(v => v.key === 'back_status_switch') && true
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编号',
          field: 'codeText',
          sort: true
        },
        {
          title: '资产编号',
          field: 'assetsCode',
          sort: true
        },
        {
          title: '资产名称',
          field: 'assetsName',
          sort: true
        },
        {
          title: '价值',
          dataType: Number,
          field: 'jz',
          sort: true
        },
        {
          title: '申请人',
          field: 'userName',
          sort: true
        },
        {
          title: '申请科室',
          field: 'applyOrgName',
          sort: true
        },
        {
          title: '预估维修金额',
          field: 'repairJe',
          sort: true
        },
        {
          title: '维修厂家',
          field: 'repairCmp',
          sort: true
        },
        {
          title: '申请理由',
          field: 'reason',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.reason
              }
            })
          }
        },
        {
          title: '申请日期',
          field: 'createTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          }
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      } else {
        let action = this.chooseData.actions.find(v => v.key === 'back_status_switch')
        if (action) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该维修申请吗?'})
          if (result) {
            await request.switchStatus(this.chooseData.id, {
              statusActionKey: action.key,
              statusActionRecordId: action.statusActionRecordId,
            })
            vm.loadData()
          }
        }
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'assetsRepairApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.statusConfig = {
        norm: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'norm')),
        mini: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'mini')),
      }
    },
    async loadData (parm) {
      let data = []
      if (this.$authFunsProxy.get) {
        data = await request.get(parm)
      } else if (this.$authFunsProxy.getMyOrg) {
        data = await request.getMyOrg()
      }
      data.forEach(v => {
        v.codeText = 'WXSQ' + v.id
        if (v.assets) {
          v.assetsCode = v.assets.code
          v.assetsName = v.assets.name
          v.assetsUseOrgName = v.assets.useOrgName
          v.jz = v.assets.jz
        }
      })
      return data
    }
  },
  created () {
    this.loadConfig()
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      statusMap: {},
      statusConfig: {},
      modal: false,
      chooseData: null
    }
  }
}
</script>
